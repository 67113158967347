.spinner {
    padding-left: 32;
    display: inline-flex;
    align-items: center;
}

.header {
    display: flex;
    align-items: center;
    gap: 16px;
}

.title {
    display: inline-flex;
    min-height: 80px;
    align-items: center;
    font-size: xx-large;
}