.form {
    margin: auto;
    width: 800px;
    padding: 24px;
}

.customize-button-container {
  margin-left: auto;
  margin-right: 0;
}

.form-item {
  padding: 8px;
  text-align: left;
}

.form-textarea {
  width: 100%;
  resize: none;
}

.form-textfield {
  width: 100%;
}

.form-label {
  padding-bottom: 4px;
  display: block;
}

.form-sign {
  border: 2px solid #268DA5;
  border-radius: 8  px;
}