.container {
    display: flex;
    z-index: 1;
}

.header {
    position: sticky;
    top: 0px;
    z-index: 2;
}

