.signature-canvas {
    height: 250px;    
    border: 1px solid #AAAAAA;
    border-radius: 16px;
    background-color: #EBEBEB;
    margin-bottom: 16px;
    margin-right: 16px;
}

.text-field {
    width: 250px
}

.img-signature {
    height: 200px;    
    margin-bottom: 16px;
    margin-right: 16px;
}