.rootContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 16;
    border-color: black;
    gap: 8px;
    width: 80%;
    margin: 0 auto;
}

.studentsContainer {
    background-color: 'white';
    max-height: 400px;
    border: 1px solid #AAAAAA;
    border-radius: 16px;

    width: 100%;
    overflow: auto;
    align-items: center;
}

.studentsList {
    width: 100%;
    height: 100%;
}